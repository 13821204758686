<template>
    <div class="content" v-loading="loading"
         element-loading-text="生成户室中，请稍后...">
        <!--        房屋批量生产-->
        <h5>生成户室</h5>
        <el-form ref="form"
                 :model="form"
                 label-width="110px"
                 :inline="true"

        >
            <el-form-item label="楼层数：">
                <el-input style="width: 193px" placeholder="请输入楼层数" :disabled="true" v-model="form.floorNum"
                          size="mini"></el-input>
            </el-form-item>
            <el-form-item label="房号规则：">
                <el-select v-model="form.region" size="mini" placeholder="请选房号规则">
                    <el-option label="房号" value="0"></el-option>
                    <el-option label="单元号-房号" value="2"></el-option>
                    <el-option label="楼幢-单元号-房号" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="户室类型：">
                <el-select v-model="form.houseType" size="mini" placeholder="请选房号规则">
                    <el-option label="商品房" value="0"></el-option>
                    <el-option label="公租房" value="1"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <el-form ref="form" :model="form" label-width="110px" :inline="true">
            <el-form-item label="商铺层数：">
                <el-input v-model="form.mark" clearable placeholder="请输入商铺层数" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="单元数：">
                <el-input v-model="form.unitNum" :disabled="true" clearable placeholder="请输入单元数" size="mini"></el-input>
            </el-form-item>
        </el-form>
        <!--        form.unitNum-->
        <div v-for="(item,index) in form.unitNum" :key="index">
            <el-divider content-position="left">{{index+1}}单元</el-divider>
            <el-form ref="form" :model="form2" label-width="110px" :inline="true">
                <el-form-item label="每层户数：">
                    <el-input v-model="form2['input-'+String(index)]" @input="changeChild(index)" clearable
                              placeholder="请输入每层层数" size="mini"></el-input>
                </el-form-item>
                <div>
                    <el-form-item :label="(index1+1)+'号房屋面积'"
                                  v-for="(item1,index1) in Number.parseInt((form2['input-'+String(index)]||1))"
                                  :key="index1">
                        <el-input v-model="form1['input'+String(index)+'-'+String(index1)]" clearable
                                  :placeholder="(index1+1)+'号房屋面积'" size="mini"></el-input>
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="btn1">
            <el-button type="primary" size="small" @click="shureSend" :disabled="dis">生成户室</el-button>
            <el-button @click="back" size="small">返回</el-button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "batchProduct",
        data() {
            return {
                url: this.$Config.base_server,
                form1: {//第一层循环
                    // input-0: "1"
                    // input-1: "2"
                    // input-2: "3"
                    // input-3: "4"
                },
                form: {},
                form2: {//每层户数
                    // input-0: "1"
                    // input-1: "2"
                    // input-2: "3"
                    // input-3: "4"
                },
                allNum: 0,
                loading: false,
                dis: false
            }
        },
        mounted() {
            // this.form = this.$route.params;
            this.form = JSON.parse(localStorage.getItem("batchProduct"));
        },
        methods: {
            changeChild(index) {

            },
            shureSend() {

                this.$confirm('确定生成户室吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.send();
                }).catch(() => {

                });
            },
            send() {
                const that = this;
                that.loading = true;
                const data = {
                    villageId: this.form.villageId,//小区id
                    towerId: this.form.towerId,//楼幢id
                    towerNum: this.form.towerNum,//楼幢号
                    floor: this.form.floorNum,//楼层数
                    role: this.form.region,//房号规则
                    houseType: this.form.houseType,//户室类型
                    unitNum: this.form.unitNum,//单元数
                    house_num: Object.values(this.form2),//每层户数
                    area: Object.values(this.form1),//二层循环
                    mark: this.form.mark// 商铺层数
                }
                $.ajax({
                    url: this.url + "/api-public/opehouse/gener?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(data),
                    contentType: 'application/json',
                    type: 'post',
                    success: function (res) {
                        that.loading = false;
                        if (res.resp_code == 0) {
                            //生成户室按钮禁用
                            that.dis = true;
                            that.$message({
                                dangerouslyUseHTMLString: true,
                                message: res.resp_msg,
                                type: 'success',
                                duration: 3000,
                                onClose: function () {
                                    //跳回楼幢页面
                                    that.$router.push("deveTower");
                                }
                            });
                        }
                        if (res.resp_code == 1) {
							var s = res.resp_msg.replace(/&lt;\/br&gt/g,"");
                            that.$message({
                                dangerouslyUseHTMLString: true,
                                message: s,
                                type: 'error',
                                duration: 3000
                            });
                        }
                        if (res.resp_code == 500) {
                            that.$message({
                                dangerouslyUseHTMLString: true,
                                message: res.resp_msg,
                                type: 'error',
                                duration: 3000
                            });

                        }

                    }, error: function (xhr, textStatus, errorThrown) {
                        if (xhr.status == 500) {
                            that.$message({
                                dangerouslyUseHTMLString: true,
                                message: "生成户室异常，稍后重试",
                                type: 'error',
                                duration: 3000
                            });
                        }
                        that.loading = false;
                    }
                });
            }, back() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .content {
        padding: 15px 111px;
        box-sizing: border-box;
        background: #fff;
        width: 1200px;
        margin: 0 auto;

        h5 {
            font-size: 16px;
        }
    }

    .btn1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>